import React, { useRef } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import logo from '../img/icons/logo512.png'

const EventContract = () => {
	const formRef = useRef(null)

	const handleGeneratePdf = async e => {
		e.preventDefault() // Prevent the default form submission behavior

		const form = formRef.current

		if (!form) {
			console.error('Form element not found.')
			return
		}

		// Use html2canvas to capture the content of the specific form element
		const canvas = await html2canvas(form)

		// Create a new jsPDF instance
		const pdf = new jsPDF('p', 'mm', 'a4')

		// Calculate the width and height of the PDF page to match the captured content
		const pdfWidth = pdf.internal.pageSize.getWidth()
		const pdfHeight = (canvas.height * pdfWidth) / canvas.width

		// Add the captured image to the PDF
		pdf.addImage(
			canvas.toDataURL('image/jpeg', 1.0),
			'JPEG',
			0,
			0,
			pdfWidth,
			pdfHeight
		)

		// Save the PDF
		pdf.save('event-contract.pdf')
	}

    return (
		<div>
			<div className="section bg-black">
				<form
					onSubmit={handleGeneratePdf}
					ref={formRef}
					id="booking"
					className="max-w-4xl p-5 md:p-10 lg:p-20 mx-auto rounded-md bg-white text-[14pt] text-black font-century"
				>
					<div class="flex justify-between">
						<div>
							<img
								src={logo}
								alt=""
								className="w-24 md:w-32 lg:w-40 h-24 md:h-32 lg:h-40 mx-left md:block"
							/>
						</div>
						<div className="w-35 h-35">
							<div>
								<p className="">
									Andrii Gedzyk <br />
									+1 647 378 8155 <br />
									andriy@firesax.com
									<br />
									www.firesax.com
								</p>
							</div>
						</div>
					</div>
					<div>
						<p className="text-[14pt] md:text-[pt] lg:text-[14pt] text-center my-6 md:my-10 lg:my-16">
							LIVE MUSICAL <br />
							PERFORMANCE CONTRACT
						</p>
						This agreement is made on the date of 11.09.2023 by and
						between <b>Andrii Gedzyk</b> (hereinafter referred to as
						"Musician") and{' '}
						{/* <input
							id="name"
							name="name"
							type="text"
							placeholder="John Lenon"
							autoComplete="on"
							required="Required"
							class="block px-4  border-gray-300 rounded-md font-bold focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
						/> */}
						<p className="inline">
							<input
								id="name"
								name="name"
								type="text"
								autoComplete="on"
								required="Required"
                                placeholder="Enter full name"
                                class="pl-6 rounded-md font-bold focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
							/>
						</p>
						(hereinafter referred to as "Client").
						<br />
						<br />
						<p></p>
						<p>
							Services: The Musician agrees to perform live music
							at the event specified below:
							<br />
							<br />
						</p>
						<p>
							Event: Crystal & Steven Wedding
							<br />
							Date: 21.09. 2024
							<br />
							Location: Earth to table the farm. 250 concession 4
							w Millgrove ontario
							<br />
							<br />
						</p>
						<p>
							Performing time: <br />
							Cocktail hour: 2sets per 25min with 10min break
							<br />
							Dance sets: 2 sets per 10/15min performance, during
							dance time, with fire
							<br />
							<br />
						</p>
						<p>
							Payment:
							<br /> The Client agrees to pay the Musician the sum
							of <b>1000$</b> for their services. This payment
							will be made as follows: <b>200$</b> upon signing of
							the contract, and 800$ on the same day of the event
							after the performance. Payment may be made in the
							form of cash or e-transfer.
							<br />
							<br />
						</p>
						<p>
							Responsibilities of the Musician:
							<br /> The Musician shall provide their own
							equipment such as microphone, wireless system and
							instrument necessary for their performance. <br />
							The Musician shall not provide sound equipment such
							as speakers, sound mixer, and audio cables without
							the Client's prior consent. The Musician shall
							arrive at least 30 min before the start of the event
							to set up and sound check. The Musician shall
							perform music on their own mind during the event and
							shall not exceed the agreed-upon performance time
							without the Client's prior consent.
							<br />
							<br />
						</p>
						<p>
							Responsibilities of the Client: <br />
							The Client shall provide a suitable area for the
							Musician to perform, sound equipment such as
							speakers, sound mixer, and audio cables including
							access to electricity, if necessary. The Client
							shall make all necessary arrangements to provide the
							Musician with access to the event venue not less
							than 30 minutes before the Event and 30 minutes
							after the completion of the Event for setup.
							<br />
							<br />
						</p>
						<p>
							Cancellation: <br />
							If the Client cancels the event, the deposit is
							non-refundable. If the Musician cancels the event
							for any reason, the deposit shall be refunded in
							full to the Client. The Client will be responsible
							for any non refundable expenses incurred by the
							Musicians. If the event is canceled within 2 weeks
							before, the Client will be responsible for paying
							50% of the total fee. In the event of cancellation
							due to impossibility, such as natural disaster or
							other unforeseen circumstances, the Client shall not
							be responsible for payment.
							<br />
							<br />
						</p>
						<p>
							Rescheduling:
							<br /> In the event that the Client wishes to
							reschedule the event, the Client must provide
							written notice of such request to the Musician no
							less than 30 days prior to the originally scheduled
							event date. The Musician will make reasonable
							efforts to accommodate the rescheduling request, but
							shall not be obligated to do so if the Musician is
							unable to secure the requested rescheduling date. If
							the Musician is able to secure the requested
							rescheduling date, the Client may be required to pay
							additional fees or a fee increase to compensate the
							Musician for any costs incurred as a result of the
							rescheduling. Any rescheduling of the event shall
							not relieve the Client of its obligation to pay the
							Musician the full amount agreed to under this
							contract.
							<br />
							<br />
						</p>
						<p>
							Indemnification: <br />
							The Client shall indemnify and hold the Musician
							harmless from and against any and all claims,
							damages, liabilities, costs, and expenses, including
							reasonable attorneys' fees, arising out of or in
							connection with the Musician's performance under
							this agreement. <br /> <br />
						</p>
						<p>
							Entire Understanding: <br />
							This agreement constitutes the entire understanding
							of the parties and supersedes all prior and
							contemporaneous agreements and understandings,
							whether oral or written. This agreement may not be
							amended or modified except in writing signed by both
							parties. <br /> <br />
						</p>
						<div className="flex flex-col mt-[6rem] grow">
							<label for="date">Date:</label>
							<input type="text" id="date" value="01/01/2021" />
						</div>
						<p>
							IN WITNESS WHEREOF, the undersigned have executed
							this agreement as of the date first above written.
							<br /> <br />
						</p>
						<p>
							(Client's Signature)___________________ Date:
							_________________ Andrii Gedzyk Date: 11.09.2023
						</p>
					</div>

					<div class="flex justify-center mt-6">
						<button
							type="submit"
							class="flex items-center justify-between
								 px-6 py-3 text-sm tracking-wide text-white capitalize
								  transition-colors duration-300 transform bg-blue-500 
								  rounded-md hover:bg-blue-600 focus:outline-none focus:ring
								   focus:ring-blue-300 focus:ring-opacity-50"
						>
							<span>Save contract</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-5 h-5 rtl:-scale-x-100"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default EventContract
