import React from 'react'
import QuestionsList from '../components/QuestionsList.js';

const Questions = () => {
  return (
    <div>
      <QuestionsList/>
    </div>
  )
}

export default Questions
