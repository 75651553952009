import React from 'react'
import BookingForm from '../components/BookingForm';

const BookingPage = () => {
  return (
    <BookingForm />
  )
}

export default BookingPage
