import React from 'react'

import HeroImg from '../img/home/7cd7bfc2-ca03-47f2-9406-057e8976c4e73.jpg'
//import components
import Brands from '../components/Brands'
import About from './About'
import Testimonials from '../components/Testimonials'

import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { transition1 } from '../transitions'

const Home = () => {
	return (
		<div>
			<section
				initial={{ opacity: 0, y: '50%' }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, x: '50%' }}
				transition={transition1}
				id="home"
				className="relative bg-[url(https://lh3.googleusercontent.com/fife/ALs6j_GjF8uKDBRkVNJR-K33NSPScLhGYa0xoQ6O5d6mrx8gjwkw_schCMCoHNS0InbPnXLDoAtru0cjsIutBuaeDGU5iXCAcBqyczddEipVum6s1JSD6OwwMTt5fU8kMYW9sWCoy2IMQ432TadsK7T3ftuPq5Fvd9q_xeraQnidSggndnNWsq0GPKEAqvteQWT5n5JiRoaVUEJ9L00tsHhm0Geho66z96vO2IW_Eoy87T37O-3uu6z3ruO0EUlrSGQu2edZPAjBV-lEh1I4pn8CK28z1Qp-Z3yYZM_Bu0K-rQK8LJ9N8R8IKSx3Gk9rDG8OMt46j6qdAUdFjCUXF526usq1byZlXyJLqyBb77LTrYdm42qnffaAdNU2YbY6LNr8yusIf2uP3n1xXVJm6CSGNcRrXSWroa9_kAkQlbh0WzlxCtmyq_w0nPfJncIuRexSLefzXqVfv7w6TGMzc3eJ8NayZe_NyqZQ9SYf8uHYvYtsbRhT_VyQmOnXtXz4PrkG3IkaESEv1kIfSgWTIuerWlSKHLr6zGqtCn4USI6myBp3RWRfBmGsZccqzo7JM8QAPYhRL35P27qq_xtk2IqOAGuetm6xty7XSBLhW0ktqIxi-3gkWe_1otFsSqBUUniVW8b6tH1v7es8ThShFwY30nhogvGQHN4D4D5jufW-cnquLBsHBv8T_nWWoyCqe1gDZefjLGIALVBtlCCtHoKVqS1gixU0NtpkVX04faPHD8b_Y24cfUL5YMtYYZOQ4L3gXDIvJLpIHOjWHLFLisMgK06JW0Cm6QCaS7KjqAu_YzGizoetvsSqirDa-h03EJS3tDuxLDcm4q-p_bUG3f2E6z_ywIDZpYBp1Xm1ViM_CHpPTudFJrVTZf0haYjE9wDEj3rKV6iMj4XREr7kxk-TTRc0ECHnyBCqXY1yoEijS3MkgJ2_z8lkkbSs-pr2vWUgy7IHYIYZtr8rpVE8OG9GsJp4fMI9KFiHcIYdFqw_WJpZ8XNFYYYW-x7XhCE2GO6IE2tnHLVDOwb6cz-ESk5blbpZ9Wj0p_mAY3voYKFh6QXzp9o57_A8R6b92A-vGK-1y-07bRQAQJEnlrzAVGjKqOqR5tlmn69rox7MvdiXcDkjvysisnmO9LkZw5Sknlg3gCSWyu8Q7YNgbzErlUjZSWipFZR4MRq7E-AzguD44-ryOuNO5o-Arjj0hoRzM3tOl1jx6BXAJ4HFdK37_XIU3WbemtW70z4MzMsbc9TwzfrWwYks37cnA4Y8YohURj4MBWzIBlfyFLeQbCAL8E3pf25syBDAQ-lldtNnv6lJ4JLgXnelj8VAqos5RESnB-noV2IrAeh-X0di89clf0_QN40PC523Xz0xin21tVUH4NOtOUpqptjKxJYc480Da12laCFJTnNxUPGi4Ql29lT10FQtKB1YzWQxcVz2fN3T1j9JiXadbqDFSumSZOkwN2GhONHAUI7CUxcESq4pm-yDXsTtpfeuEzVDKhLteKmzw0IdhaTjQANalfaRNWzNr04PRycUaVaT4ljf_Q-7X0fQbEW0YVTed5JKv9V7_DWw64-TtU4-Hx3lmvJkZle6NN2va0EA37vE4i6J7He4ktOMepD2xHXW3190tUD9n7EoIexqhQ2h5yzw-pEyaCClscrydMMTGeInYPSgXTWuhjUNdMauG_UaPkIAVrEn7k21GiNNnc7mNGqXEwi0_Q=w1920-h911)] bg-cover bg-center bg-no-repeat h-screen"
			>
				<div className="absolute inset-0 bg-white/50 sm:bg-transparent sm:from-white/50 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

				{/* text and image wrapper */}
				<div className="flex justify-start mx-auto px-4 pt-[450px] sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-44 lg:py-8">
					{/* text */}
					<motion.div
						initial={{ opacity: 0, y: '-50%' }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: '-50%' }}
						transition={transition1}
						className="max-w-xl text-left ltr:sm:text-left rtl:sm:text-right z-40"
					>
						<h2 className="text-xl leading-[40px] md:leading-tight lg:leading-[1.2] md:text-5xl  lg:text-1xl font-normal md:tracking-[-2px]">
							New Project:
						</h2>
						<h1 className="text-3xl leading-[40px] md:leading-tight lg:leading-[1.2] md:text-5xl  lg:text-7xl font-bold md:tracking-[-2px]">
							The Swarovski Saxophone
						</h1>
						<p className="hidden lg:block mt-10 max-w-lg text-24 text-white sm:text-xl/relaxed">
							Experience luxury redefined with our Crystal
							Saxophone, where exquisite craftsmanship meets
							musical performance. Adorned with Swarovski
							crystals, this saxophone is more than an
							instrument—it's a statement piece.
							<br />
						</p>
						<div className="flex justify-start mt-8 flex-wrap gap-4 text-center">
							<Link to={'/booking'}>
								<button className="btn btn-md bg-accent hover:bg-accent-hover rounded-lg md:btn-lg transition-all z-40">
									Hire me
								</button>
							</Link>
						</div>
					</motion.div>
				</div>
			</section>
			<About />
			<Brands />
			<Testimonials />
		</div>
	)
}

export default Home
