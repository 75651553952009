import React, { useState } from 'react'

//import email js
import emailjs from 'emailjs-com';
//import animation
import { motion } from 'framer-motion'
import { transition1 } from '../transitions'

//import icons
import { HiOutlinePhone } from 'react-icons/hi'
import { HiOutlineEnvelope } from 'react-icons/hi2'

import { Link } from 'react-router-dom';

import logo from '../img/icons/logo512.png'
import Success from '../pages/Success';

const Result = () => {
	return (
		<motion.div
			id="contacts"
			className="flex justify-center rounded-xl bg-white text-[#757879] p-2 items-center"
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{
				default: {
					duration: 5,
					ease: [0, 0.71, 0.2, 1.01]
				},
				scale: {
					type: 'spring',
					damping: 5,
					stiffness: 100,
					restDelta: 0.001
				}
			}}
		>
			<p>
				Thanks for being awesome!
				<br /> I have received your message and would like to thank you
				for writing to me. If your inquiry is urgent, please use the
				telephone number. Otherwise, I will reply by email as soon as
				possible.
				<br />
				Talk to you soon,
				<br /> Andriy
			</p>
		</motion.div>
	)
}

const BookingForm = () => {
	// form states
	const [eventname, setEventname] = useState('')
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [date, setDate] = useState({ startDate: new Date() })
	const [location, setLocation] = useState('')
	const [start, setStart] = useState('')
	const [end, setEnd] = useState('')
	const [firesax, setFiresax] = useState('')
	const [swarovskisax, setSwarovskisax] = useState('')
	const [regularsax, setRegularsax] = useState('')
	const [clarinet, setClarinet] = useState('')
	const [message, setMessage] = useState('')

	// submit form
	const [result, showResult] = useState(false)
	function sendEmail(e) {
		e.preventDefault()
		// console.log(eventname,name,email,phone,date,location)
		const data = {
			Eventname: eventname,
			Name: name,
			Email: email,
			Phone: phone,
			Date: date,
			Location: location,
			Start: start,
			End: end,
			Firesax: firesax,
			Swarovskisax: swarovskisax,
			Regularsax: regularsax,
			Clarinet: clarinet,
			Message: message
		}
		emailjs
			.sendForm(
				'service_mxn4oj8',
				'template_v7w9b77',
				e.target,
				'eUO1xfIXxa6azjGL0'
			)
			.then(response => {
				// clearing form field
				setEventname('')
				setName('')
				setEmail('')
				setPhone('')
				setDate('')
				setLocation('')
				setStart('')
				setEnd('')
				setFiresax('')
				setSwarovskisax('')
				setRegularsax('')
				setClarinet('')
				setMessage('')
			})
			.then(result => {
				console.log(result.text)
			})

		e.target.reset()
		showResult(true)
	}

	//hide result
	setTimeout(() => {
		showResult(false)
	}, 5000)
	const icon = {
		hidden: {
			opacity: 0,
			pathLength: 0,
			fill: 'rgba(255, 255, 255, 0)'
		},
		visible: {
			opacity: 1,
			pathLength: 1,
			fill: 'rgba(255, 255, 255, 1)'
		}
	}

	return (
		<section
			id="booking"
			initial={{ opacity: 0, y: '100%' }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: '100%' }}
			transition={transition1}
			className="section bg-black"
		>
			<div className="container mx-auto h-full relative ">
				<div
					className="flex-col md:flex-row-reverse lg:flex-row h-full
				items-center justify-start md:gap-x-0 lg:gap-x-24 lg:text-left mx-4 lg:mx-0"
				>
					<motion.div
						initial={{ opacity: 0, y: '-80%' }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: '-80%' }}
						transition={transition1}
						className="flex-1 lg:pt-22 lg:w-auto first-letter
						            z-10 flex flex-col justify-center items-center"
					></motion.div>
				</div>
			</div>

			<motion.section
				initial={{ opacity: 0, x: '-80%' }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 0, x: '80%' }}
				transition={transition1}
				className="max-w-4xl p-6 mx-auto rounded-md shadow-md bg-gray-800"
			>
				<motion.img
					style={{
						borderRadius: 30,
						cursor: 'pointer'
					}}
					whileHover={{ rotate: -90, scale: 0.75 }}
					src={logo}
					alt=""
					className="w-24 md:w-32 lg:w-40 h-24 md:h-32 lg:h-40 mx-auto md:block"
				/>
				<h1 class="text-[40px] md:text-[60px] lg:text-[80px] font-thin text-white tracking-widest dark:text-white text-center my-6 md:my-10 lg:my-16">
					B O O K I N G
				</h1>

				<h2 class="text-[40px] md:text-[60px] lg:text-[80px] font-zayne text-white tracking-widest dark:text-white text-center my-6 md:my-10 lg:my-16">
					form
				</h2>

				<form autoComplete="on" onSubmit={sendEmail}>
					<div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
						{/* Event name */}
						<div>
							<label
								class="text-white dark:text-gray-200"
								for="eventname"
							>
								Event name
							</label>
							<input
								id="eventname"
								name="event"
								type="text"
								placeholder="John & Diana Wedding"
								onChange={e => setEventname(e.target.value)}
								value={eventname}
								className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-600 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
							/>
						</div>
					</div>

					<h1 class="text-xl font-bold text-white capitalize dark:text-white mt-10">
						CLIENT INFORMATION
					</h1>

					{/* Name */}
					<div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
						<div>
							<label
								class="text-white dark:text-gray-200"
								for="name"
							>
								Name
							</label>
							<input
								id="name"
								name="name"
								type="text"
								placeholder="John"
								autoComplete="on"
								required="Required"
								onChange={e => setName(e.target.value)}
								value={name}
								class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-600 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
							/>
						</div>

						{/* Email */}
						<div>
							<label
								class="text-white dark:text-gray-200"
								for="emailAddress"
							>
								Email Address
							</label>
							<div class="relative w-full">
								<div className=" left-0 flex items-center pl-3">
									<HiOutlineEnvelope className="text-gray-400 absolute inset-y-5" />
								</div>
							</div>
							<input
								id="emailAddress"
								name="user-email"
								icon="users"
								iconPosition="left"
								type="email"
								autoComplete="on"
								placeholder="john@netflix.com"
								required="Required"
								onChange={e => setEmail(e.target.value)}
								value={email}
								class="block w-full px-10 py-2 mt-2 text-gray-700 placeholder-gray-600 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
							/>
						</div>

						{/* phoneNumber */}
						<div>
							<label
								class="text-white dark:text-gray-200"
								for="phoneNumber"
							>
								Phone number
								<div className="relative w-full">
									<div className=" left-0 flex items-center pl-3">
										<HiOutlinePhone className="text-gray-400 absolute inset-y-5" />
									</div>
									<input
										id="phone"
										name="phone"
										type="tel"
										placeholder="example: 16473788155"
										maxLength={13}
										required="Required"
										onChange={e =>
											setPhone(
												e.target.value.replace(
													/[^\d+]+/g,
													''
												)
											)
										}
										value={phone}
										className="block w-full px-9 py-2 mt-2 text-gray-700 placeholder-gray-600 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
									/>
								</div>
							</label>
						</div>

						{/* Data */}
						<div>
							<label
								class="text-white dark:text-gray-200"
								for="startDate"
							>
								Date of Event
							</label>
							<input
								id="date"
								name="date"
								type="date"
								onChange={e => setDate(e.target.value)}
								value={date}
								class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
							/>
						</div>
					</div>

					<h1 class="text-xl font-bold text-white capitalize dark:text-white mt-10">
						PERFORMANCE INFORMATION
					</h1>
					<div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
						{/* Location & Address */}
						<div>
							<label
								class="text-white dark:text-gray-200"
								for="location"
							>
								Performance Location & Address
							</label>
							<input
								id="location"
								name="location"
								type="text"
								placeholder="Four Seasons Hotel, 60 Yorkville Ave, Toronto"
								onChange={e => setLocation(e.target.value)}
								value={location}
								class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-600 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
							/>
						</div>
					</div>

					<div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
						{/* Start Time */}
						<div>
							<label
								for="time"
								className="text-white dark:text-gray-200"
							>
								Musician Start Time
								<input
									type="time"
									name="start"
									id="start"
									onChange={e => setStart(e.target.value)}
									className="w-full border border-gray-300 rounded-md dark:bg-gray-800 py-3 px-6 mt-2 dark:text-gray-300 outline-none focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
								/>
							</label>
						</div>
						{/* End Time */}
						<div>
							<label
								for="time"
								className="text-white dark:text-gray-200"
							>
								Musician End Time
							</label>
							<input
								type="time"
								name="end"
								id="end"
								onChange={e => setEnd(e.target.value)}
								className="w-full border border-gray-300 rounded-md dark:bg-gray-800 py-3 px-6 mt-2 dark:text-gray-300 outline-none focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
							/>
						</div>
					</div>

					<div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-1">
						{/* Would you like saxophone with fire? */}
						{/* <div class="mb-5">
							<label class="mb-3 block text-gray-200">
								Would you like saxophone with fire?
							</label>
							<div class="flex radio items-center space-x-6">
								<label class="flex radio items-center">
									<input
										type="radio"
										name="radio1"
										id="radioButton1"
										onChange={e =>
											setWithfire(e.target.value)
										}
										value="withfire"
										class="h-5 w-5"
									/>
									<label
										for="radioButton1"
										class="pl-3 text-gray-200"
									>
										Yes
									</label>
								</label>
								<label class="flex radio items-center">
									<input
										type="radio"
										name="radio2"
										id="radioButton2"
										onChange={e =>
											setNofire(e.target.value)
										}
										value="noFire"
										class="h-5 w-5"
									/>
									<label
										for="radioButton2"
										class="pl-3 text-gray-200"
									>
										No
									</label>
								</label>
							</div>
						</div> */}

						<label>
							<h3 className="mb-4 text-gray-900 dark:text-white">
								Instruments
							</h3>
							<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
								<li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
									<div className="flex items-center px-3">
										<input
											id="firesax-checkbox-list"
											name="withFireSax"
											type="checkbox"
											onChange={e =>
												setFiresax(e.target.value)
											}
											value="firesax"
											className="w-6 h-6 p-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
										/>
										<label
											for="firesax-checkbox-list"
											className="w-full py-3 mx-2 text-sm font-medium text-gray-900 dark:text-gray-300"
										>
											Fire Saxophone
										</label>
									</div>
								</li>
								<li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
									<div className="flex items-center px-3">
										<input
											id="swarovski-checkbox-list"
											name="withSwarovskiSax"
											type="checkbox"
											onChange={e =>
												setSwarovskisax(e.target.value)
											}
											value="swarovski"
											className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
										/>
										<label
											for="swarovski-checkbox-list"
											className="w-full py-3 mx-2 text-sm font-medium text-gray-900 dark:text-gray-300"
										>
											Swarovski Saxophone
										</label>
									</div>
								</li>
								<li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
									<div class="flex items-center px-3">
										<input
											id="regular-checkbox-list"
											name="withRegularSax"
											type="checkbox"
											onChange={e =>
												setRegularsax(e.target.value)
											}
											value="regular"
											class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
										/>
										<label
											for="regular-checkbox-list"
											class="w-full py-3 mx-2 text-sm font-medium text-gray-900 dark:text-gray-300"
										>
											Regular Saxophone
										</label>
									</div>
								</li>
								<li class="w-full dark:border-gray-600">
									<div class="flex items-center px-3">
										<input
											id="clarinet-checkbox-list"
											name="withClarinet"
											type="checkbox"
											onChange={e =>
												setClarinet(e.target.value)
											}
											value="clarinet"
											class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
										/>
										<label
											for="clarinet-checkbox-list"
											class="w-full py-3 mx-2 text-sm font-medium text-gray-900 dark:text-gray-300"
										>
											Clarinet
										</label>
									</div>
								</li>
							</ul>
						</label>

						<div>
							<label
								class="text-white dark:text-gray-200"
								for="passwordConfirmation"
							>
								Message
							</label>
							<textarea
								id="textarea"
								name="message"
								type="textarea"
								onChange={e => setMessage(e.target.value)}
								value={message}
								class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
							></textarea>
						</div>
					</div>

					<div class="flex justify-end mt-6">
						<button
							type="submit"
							class="flex items-center justify-between
								 px-6 py-3 text-sm tracking-wide text-white capitalize
								  transition-colors duration-300 transform bg-blue-500 
								  rounded-md hover:bg-blue-600 focus:outline-none focus:ring
								   focus:ring-blue-300 focus:ring-opacity-50"
						>
							<span>Submit </span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-5 h-5 rtl:-scale-x-100"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>
					</div>
				</form>
				<div className="row">{result && <Result />} </div>
			</motion.section>
		</section>
	)
};

export default BookingForm;
